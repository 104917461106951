import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { alpha, useTheme } from '@mui/material/styles';

import { bgGradient } from '@/app/theme/css';
import InViewAnimationComponent from '../in-view-animation-container';

export default function AuthLayout({ children }) {
  const theme = useTheme();

  return (
    <Stack
      component="main"
      direction="row"
      sx={{
        minHeight: '100vh',
      }}
    >
      <Stack
        flexGrow={1}
        spacing={10}
        alignItems="center"
        justifyContent="center"
        sx={{
          ...bgGradient({
            color: alpha(
              theme.palette.background.default,
              theme.palette.mode === 'light' ? 0.88 : 0.94
            ),
            imgUrl: '/assets/background/overlay_2.jpg',
          }),
        }}
      >
        <InViewAnimationComponent>
          <Stack
            spacing={4}
            sx={{
              width: 1,
              mx: 'auto',
              maxWidth: 480,
              px: { xs: 4, md: 8 },
              // pt: { xs: 15, md: 20 },
              py: { xs: 4, md: 8 },
              bgcolor: theme.palette.background.default,
              boxShadow: theme.customShadows.z16,
              borderRadius: theme.shape.borderRadius * 0.5,
            }}
          >
            <Typography variant="h3" sx={{ maxWidth: 480, textAlign: 'center' }}>
              Activate your device and manage your playlists
            </Typography>
            {children}
          </Stack>
        </InViewAnimationComponent>
      </Stack>
    </Stack>
  );
}
