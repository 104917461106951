/* eslint-disable no-underscore-dangle */

'use client';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import qs from 'qs';

import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';

import { useSnackbar } from 'notistack';
import { useRouter } from 'next/navigation';
import { useCallback, useEffect } from 'react';
import { useBoolean } from '@/app/components/hooks/use-boolean';
import Iconify from '@/app/components/iconify';

import FormProvider from '@/app/components/hook-form/form-provider';
import RHFTextField from '@/app/components/hook-form/rhf-text-field';
import AuthLayout from '@/app/components/layouts/auth';
import { axiosInstance } from '@/app/utilities/helpers/axios';
import { encryptRequest, decryptResponse } from '@/app/utilities/helpers/security';
import { paths } from '@/app/utilities/consts';
import { isValidMacAddress } from '@/app/utilities/helpers/common';

export const LoginView = ({ macAddress, deviceKEY }) => {
  const deviceKey = useBoolean();
  const { enqueueSnackbar } = useSnackbar();
  const router = useRouter();

  const LoginSchema = Yup.object().shape({
    macAddress: Yup.string()
      .matches(/^([0-9A-Fa-f]{2}:){5}[0-9A-Fa-f]{2}$/, 'Invalid MAC address format')
      .required('Mac address is required'),
    deviceKey: Yup.string().required('Device key is required'),
  });

  const defaultValues = {
    macAddress: '',
    deviceKey: '',
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const loginAction = useCallback(
    async data => {
      try {
        const body = {
          data: encryptRequest({
            mac_address: data.macAddress,
            device_key: data.deviceKey,
          }),
        };

        const response = await axiosInstance.post(
          '6hfUGOe6WhVGR2rbp5DaygDdAq58NKCh',
          qs.stringify(body),
          { withCredentials: true }
        );

        const responseData = decryptResponse(response.data);
        if (responseData.success) {
          const redirectTo = localStorage.getItem('redirectAfterLogin') || paths.activate;
          router.push(redirectTo);

          const { token } = responseData;
          localStorage.setItem('token', token);

          enqueueSnackbar('Log in successful', { variant: 'success' });
        } else {
          enqueueSnackbar('Mac address or device key does not match', { variant: 'error' });
        }
      } catch (error) {
        enqueueSnackbar('Server Error', { variant: 'error' });
      }
    },
    [enqueueSnackbar, router]
  );

  const onSubmit = handleSubmit(loginAction);

  useEffect(() => {
    if (macAddress && deviceKEY && isValidMacAddress(macAddress)) {
      loginAction({
        macAddress,
        deviceKey: deviceKEY,
      });
    }
  }, [deviceKEY, loginAction, macAddress]);

  const loginHead = (
    <Stack spacing={2} sx={{ mb: 5 }} alignItems="center">
      <Typography variant="h5">Login to add your playlist</Typography>
    </Stack>
  );

  const loginForm = (
    <Stack spacing={2.5}>
      <RHFTextField name="macAddress" label="Mac Address *" type="mac-address" />

      <RHFTextField
        name="deviceKey"
        label="Device Key *"
        type={deviceKey.value ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={deviceKey.onToggle} edge="end">
                <Iconify icon={deviceKey.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        Login
      </LoadingButton>
    </Stack>
  );

  return (
    <AuthLayout>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        {loginHead}

        {loginForm}
      </FormProvider>
    </AuthLayout>
  );
};
