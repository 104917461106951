/* eslint-disable camelcase */

'use client';

import { useSearchParams } from 'next/navigation';

import MasterLayout from '@/app/components/layout/master-layout';
import { LoginView } from './login-view';

export default function DeviceLoginView() {
  const searchParams = useSearchParams();

  const mac_address = searchParams.get('mac_address');
  const device_key = searchParams.get('device_key');

  return (
    <MasterLayout>
      <LoginView macAddress={mac_address} deviceKEY={device_key} />
    </MasterLayout>
  );
}
